'use strict'

import {registerPermissionGrantedCallback, getServicePermission} from "../libs/@elements/cookie-permissions";

const COOKIE_INFORMATION_CONSENT_UPDATE_EVENTNAME = 'cookie_consent_update'

class TrackingHandler {
    supports() {
    }

    handle(payload, callback) {
    }
}

class TagManagerHandler extends TrackingHandler {
    constructor() {
        super();

        this._allowed = false;

        registerPermissionGrantedCallback("tagManager", () => {
            this._allowed = true;
        });
    }

    supports(type) {
        return dataLayer != null && type === "gtm" && this._allowed;
    }

    handle(payload) {
        window.addEventListener(COOKIE_INFORMATION_CONSENT_UPDATE_EVENTNAME, () => {
            dataLayer.push(payload);
        })
    }
}

const handlers = [
    new TagManagerHandler()
];

export function initInScope($scope) {
    $scope.find(".js-tracking").each(function () {
        track($(this), [new DelegationConstraint('.js-compare-list__tracking, .js-wishlist__tracking')]);
    });

    $scope.find(".js-compare-list__tracking, .js-wishlist__tracking").each(function () {
        track($(this), [new HasClassConstraint('is-active')]);
    });
}

function track($element, constraints) {
    let action = $element.data("tracking-action");
    let type = $element.data("tracking-type");
    let payload = $element.data("tracking-payload") ? $element.data("tracking-payload") : null;

    if (action !== undefined) {
        $element.on(action, function (event) {
            if (!evaluateConstraints(constraints, $element, event)) {
                return;
            }
            handle(payload, type);
        });
    } else {
        if (!evaluateConstraints(constraints, $element)) {
            return;
        }
        handle(payload, type);
    }
}

function evaluateConstraints(constraints, $element, event) {
    for (let constraint of constraints) {
        if (! constraint instanceof TrackingConstraint) {
            continue;
        }
        if (!constraint.isAllowed($element, event)) {
            return false;
        }
    }

    return true;
}

function handle(payload, type) {
        handlers.forEach((function (handler) {
            if (!handler.supports(type)) {
                return;
            }

            if (payload && payload.ecommerce && !payload.dontClearECommerceObj) {
                handler.handle({ ecommerce: null });
            }

            handler.handle(payload);
        }));
}

/*
 * Push tracking calls from JSON responses into dataLayer
 *
 * The __tagManagerPayload variable is populated for JSON responses inside the TagManagerSubscriber
 * @see \App\EventSubscriber\Tracking\TagManagerSubscriber::onResponse
 */
export function handleTrackingOnPromise(promise) {
    promise.then(function (response) {
        return response.clone().json();
    }).then(data => {
        if (data.__tagManagerPayload && getServicePermission("tagManager")) {
            if (data.__tagManagerPayload.length) {
                $.each(data.__tagManagerPayload, function (index, payload) {
                    dataLayer.push(payload);
                })
            } else {
                dataLayer.push(data.__tagManagerPayload);
            }
        }
    });
}




class TrackingConstraint {
    isAllowed(element = null, event = null) {
        return true;
    }
}

class HasClassConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element, event = null) {
        return !element.hasClass(this.selector);
    }
}

class DelegationConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element = null, event) {
        if (typeof event === typeof undefined) {
            return true;
        }
        if (typeof event.target === typeof undefined) {
            return true;
        }
        const $target = $(event.target);
        if ($target.is(this.selector)) {
            return false;
        }
        const $parents = $target.parentsUntil($(event.currentTarget));
        if($parents.length) {
            for (let i = 0; i <= $parents.length; i++) {
                const $element = $($parents[i]);
                if ($element.is(this.selector)) {
                    return false;
                }
            }
        }

        return true;
    }
}
